@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.accordanceBox .accordionTitle {
  list-style-type: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  color: #555555;
  background-image: url('../../assets/open.png');
  background-repeat: no-repeat;
  background-position: right 0 top 2px;
  background-size: 14px 9px;
}

.accordanceBoxWraper {
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  /* padding: 27px 18px; */
  margin-top: 51px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #4e5155;

  @media (max-width: 768px) {
    margin-top: 35px;
  }

  position: relative;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 27px 7px;
  }

  & .accordanceBox {
    border: none !important;
    padding: 0 !important;
  }
}

.accordionList {
  text-align: left;
  width: 100%;
}

.accordionDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
}

.accordanceBox {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 14px;
  margin-bottom: 14px;

  &:after {
    content: '';
    background: red;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
  }
}

.loadingText {
  margin: 24px;
}

.accordanceBox[open] .accordionTitle {
  background-image: url('../../assets/close.png');
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  /* padding-bottom: 66.6667%; */
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background: #fdb714;
  border-radius: 2px;
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  @media (max-width: 600px) {
    /* flex-wrap: wrap; */
  }
}

.ownListingText {
  margin: 25px 12px 22px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.09375px;
  color: #40526e;
  font-family: 'Source Sans Pro';
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  margin: 0;
  padding: 25px 24px 22px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.09375px;
  color: #40526e;
  font-family: 'Source Sans Pro';
  display: flex;
  align-items: center;

  &:hover {
    color: #000;
    text-decoration: none;

    & .editIcon {
      & path {
        fill: #000;
        stroke: #000;
      }
    }
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.desktopEdit {
  display: block;

  @media (max-width: 460px) {
    display: none;
  }
}

.mobileEdit {
  display: none;

  @media (max-width: 460px) {
    display: block;
  }
}

.editIcon {
  margin: 0px 7px 0 0;

  & path {
    fill: #40526e;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 59vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: -40px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
    bottom: 19px;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  background-color: #fff;
  @media (--viewportMedium) {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    position: relative;
    justify-content: space-between;
    background-color: transparent;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    padding: 0;
  }
}

.countDownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(0, 0, 0, 0.4);
  z-index: 100;
  & .round {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    color: #fff;
  }
  & .clickPauseText {
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mainContent {
  max-width: calc(100% - 405px);
  flex-basis: calc(100% - 405px);
  flex-shrink: 0;
  flex-grow: 1;
  background: #fff;
  /* padding: 80px 38px; */
  padding: 40px 38px 80px 38px;
  position: relative;

  @media (max-width: 1024px) {
    max-width: 100%;
    flex-basis: unset;
    padding: 10px 25px;
  }
}

.socialLinks {
  position: absolute;
  right: 49px;
  top: -10px;
  @media (--viewportMedium) {
    top: 8px;
  }
  & a {
    margin-left: 20px;
    @media (--viewportMedium) {
      margin-left: 35px;
    }
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 59px;
    margin-left: 57px;
    flex-basis: 384px;
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    height: fit-content;
  }

  @media (max-width: 1300px) {
    margin-left: 25px;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  padding-top: 10px;
  z-index: 9;
  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  
  @media (--viewportMedium) {
    position: absolute;
    width: 60px;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;
  border: 3px solid #ffffff;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}

.avlLocalHire {
  background: #e5f2f3;
  border: 1.5px solid #40526e;
  border-radius: 100px;
  padding: 11px 22px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #40526e;
  position: relative;
  /* width: -webkit-fill-available; */

  & span {
    margin-right: 8px;
  }
  & .author {
    position: absolute;
    top: 100%;
    display: none;
    margin-top: 0;
    left: 0;
    right: 0;
    min-width: 150px;
    width: 70%;
    margin: 0 auto;
    height: auto;
    padding-top: 10px;
    & .contactWrapper {
      text-align: center;
      margin: 0;
      border-radius: 10px;
      display: block;
      /* max-height: 100px; */
      background-color: #3c5b81;
      text-align: center;
      color: white;
      font-size: 16px;
      line-height: 20px;
      padding: 16px;
      font-family: Arial, Helvetica, sans-serif;
      &:after {
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        background-color: #3c5b81;
        position: absolute;
        top: 5px;
        right: 50%;
        transform: rotate(45deg);
      }
    }
    & span {
      color: #fff;
    }
  }
  &:hover {
    & .author {
      position: absolute;
      top: 100%;
      display: block;
    }
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.headingBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1300px) {
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
}

.sectionHeadingBox {
  margin-top: 20px;
}

.title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9375px;
  color: #003047;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
  margin: 0;
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.expMessage {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0875px;
  color: #003047;
  margin-top: 1px;
}

.selectedLanguages {
  margin-bottom: 59px;
  margin-top: 10px;

  & span {
    background-color: #3c5b81;
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 3px;
  }
}

.author {
  display: flex;
  align-items: end;
  justify-content: end;
  width: 100%;
  composes: h5 from global;

  margin-top: 20px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    /* margin-top: 0;
    margin-bottom: 0; */
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  /* display: none; */

  @media (--viewportMedium) {
    display: inline;
  }
}

.shareButton {
  margin-right: 13px;
  & button {
    background: #e5f2f3;
    border: 1.5px solid #40526e;
    border-radius: 100px;
    padding: 11px 22px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #40526e;
    display: flex;
    & svg {
      margin-right: 10px;
    }
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1125px;
  color: #231f20;
  margin-bottom: 21px;
  margin-top: 0;
}
.specialitiesTitle {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.1125px;
  color: #231f20;
  margin-bottom: 7px;
  margin-top: 0;
}

.sectionFeatures {
  margin-top: 51px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #4e5155;

  @media (max-width: 768px) {
    margin-top: 35px;
  }
}

.mainList {
  margin: 0;
  padding-left: 5px;

  & .requirementHeading {
    padding-left: 15px;
  }
}

.tradeList {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.0888889px;
  color: #4e5155;
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionDescription {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #4e5155;
  margin: 0;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.sectionMap {
  /* padding: 0 24px; */
  margin-top: 51px;

  @media (--viewportMedium) {
    /* padding: 0;
    margin-bottom: 0; */
  }
}

.locationTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1125px;
  margin-top: 0;
  margin-bottom: 20px;
  color: #231f20;
  font-family: 'Source Serif Pro';

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 27px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-top: 70px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    /* margin-bottom: 3px; */
  }
}

.reviewsHeading {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1125px;
  margin-top: 0;
  margin-bottom: 20px;

  /* @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  } */
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mainWrapperBox {
  padding: 34px;
  background-color: #f6f3ec;

  @media (max-width: 768px) {
    padding: 25px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
}

.defaultCategoryImage {
  height: calc(0.41 * 100vw);
  max-height: 59vh;
  object-fit: cover;
  border-radius: var(--borderRadius);
  width: 100%;
}

.sectionImagePadding{
  @media (--viewportMedium) {

    padding-bottom: 100px !important;
  }
  background: #fff;
}