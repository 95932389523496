@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  overflow: auto;
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 24px;
}

:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    border-radius: 8px;
    border: 3px solid var(--marketplaceColorDark);
    padding: 12px;
  }
}

:global(.fullscreen) {
  background-color: var(--matterColorLight) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.root {
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
  & > div {
    /* aspect-ratio: 16/16; */
    min-height: 300px;
    position: relative;
    padding-bottom: 0 !important;
    width: 100%;
  }
}

.noImage {
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  /* max-height: 100%; */
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 88px;
  max-height: 88px;
  border-radius: 4px;
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */

  /* Colors */
  border-radius: 2px;
  border: 1px solid var(--marketplaceColorDark);
  background: #fff;
  min-height: 31px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  transition: var(--transitionStyleButton);
  padding: 0 12px;

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  &:hover {
    /* background-color: var(--marketplaceColor); */
    border-color: var(--marketplaceColor);
    /* color: var(--matterColorLight); */
  }

  /* @media (--viewportLarge) {
    display: block;
  } */
}

.imagesButtons {
  position: absolute;
  bottom: 19px;
  right: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.close {
  composes: marketplaceModalCloseStyles from global;

  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorDark);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}
